<template>
  <th-wrapper
    :title="$t('pages.branches.edit.sections.advanced.title')"
    collapsable
    collapsed-by-default
  >
    <el-form ref="form" :model="form">
      <el-row :gutter="20">
        <!-- Left -->
        <el-col :lg="8" :md="12">
          <!-- Print types of receipt -->
          <th-input-title
            :title="$t('pages.branches.edit.sections.advanced.print_types')"
          />

          <!-- Cancelled cart -->
          <el-form-item prop="cancellation_receipt">
            <div class="flex items-center h-10">
              <th-popover
                :text="
                  $t(
                    'pages.branches.edit.sections.advanced.cancelation_receipt'
                  )
                "
                class="mr-1"
                placement="top-start"
              />
              <el-switch
                id="cancellation_receipt"
                v-model="form.settings.revision_safety.cancellation_receipt"
                :disabled="readonly"
                :active-text="
                  $t(
                    'pages.branches.edit.form.properties.cancellation_receipt.label'
                  )
                "
              />
            </div>
          </el-form-item>

          <!-- Print merchant receipt -->
          <th-input-title
            :title="
              $t('pages.branches.edit.sections.advanced.print_merchant_receipt')
            "
          />

          <!-- has_tips -->
          <el-form-item prop="has_tips" class="mb-0 ml-6">
            <div class="flex items-center h-10">
              <el-switch
                id="has_tips"
                v-model="
                  form.receipts.printing_behavior.merchant_triggers.has_tips
                "
                :active-text="
                  $t('pages.branches.edit.sections.advanced.has_tips')
                "
                :disabled="readonly"
              />
            </div>
          </el-form-item>

          <!-- signing_failed -->
          <el-form-item prop="signing_failed" class="mb-0 ml-6">
            <div class="flex items-center h-10">
              <el-switch
                id="signing_failed"
                v-model="
                  form.receipts.printing_behavior.merchant_triggers
                    .signing_failed
                "
                :active-text="
                  $t('pages.branches.edit.sections.advanced.signing_failed')
                "
                :disabled="readonly"
              />
            </div>
          </el-form-item>

          <!-- terminal_payments -->
          <el-form-item prop="terminal_payments" class="mb-0 ml-6">
            <div class="flex items-center h-10">
              <el-switch
                id="terminal_payments"
                v-model="
                  form.receipts.printing_behavior.merchant_triggers
                    .terminal_payments
                "
                :active-text="
                  $t('pages.branches.edit.sections.advanced.terminal_payments')
                "
                :disabled="readonly"
              />
            </div>
          </el-form-item>

          <!-- merchant_main -->
          <el-form-item
            v-if="
              form.receipts.printing_behavior.merchant_triggers
                .terminal_payments
            "
            prop="merchant_main"
            class="mb-0 ml-12"
          >
            <div class="flex items-center h-10">
              <el-switch
                id="merchant_main"
                v-model="form.receipts.printing_behavior.merchant_main"
                :inactive-value="true"
                :active-value="false"
                :active-text="
                  $t('pages.branches.edit.sections.advanced.merchant_main')
                "
                :disabled="readonly"
              />
            </div>
          </el-form-item>
          <!-- on_refunds -->
          <el-form-item prop="on_refunds" class="mb-0 ml-6">
            <el-switch
              id="on_refunds"
              v-model="onRefundsSwitch"
              :active-text="
                $t('pages.branches.edit.sections.advanced.on_refunds')
              "
              :disabled="readonly"
            />
            <div v-if="showOnRefundSelect" class="flex items-center h-10">
              <th-popover
                :text="
                  $t('pages.branches.edit.sections.advanced.on_refunds_helper')
                "
                class="mr-1"
                placement="top-start"
              />
              <el-select
                id="on_refunds_select"
                v-model="
                  form.receipts.printing_behavior.merchant_triggers.on_refunds
                "
                class="w-full"
                :placeholder="$t('common.interactions.selects.placeholder')"
                :disabled="readonly"
              >
                <el-option
                  v-for="item in onRefundsOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </el-form-item>

          <!-- Content of the merchant receipt -->
          <div class="flex items-center h-10">
            <th-popover
              :text="
                $t(
                  'form.receipts.configurable_receipt.merchant.print_branch_info'
                )
              "
              class="mr-1"
              placement="top-start"
            />
            <th-input-title
              :title="
                $t('pages.branches.edit.form.properties.merchant_main.label')
              "
            />
          </div>
          <div class="flex flex-col ml-12">
            <!-- Logo -->
            <el-checkbox
              v-model="
                form.receipts.configurable_receipt.merchant.print_branch_logo
              "
              :disabled="readonly"
              class="mb-2"
            >
              {{
                $t('pages.branches.edit.sections.advanced.print_merchant.logo')
              }}
            </el-checkbox>

            <!-- Header -->
            <el-checkbox
              v-model="
                form.receipts.configurable_receipt.merchant.print_branch_header
              "
              :disabled="readonly"
              class="mb-2"
            >
              {{
                $t(
                  'pages.branches.edit.sections.advanced.print_merchant.header'
                )
              }}
            </el-checkbox>

            <!-- Footer -->
            <el-checkbox
              v-model="
                form.receipts.configurable_receipt.merchant.print_branch_footer
              "
              :disabled="readonly"
              class="mb-2"
            >
              {{
                $t(
                  'pages.branches.edit.sections.advanced.print_merchant.footer'
                )
              }}
            </el-checkbox>
          </div>

          <!-- Staff -->
          <th-input-title
            :title="$t('pages.branches.edit.sections.advanced.staff.title')"
          />

          <!-- Cashier Pin Policy -->
          <el-form-item prop="cashier_pin_policy" class="ml-6 mb-0">
            <el-switch
              v-model="form.staff.cashier_pin_policy"
              active-value="required"
              inactive-value="none"
              :active-text="
                $t(
                  'pages.branches.edit.sections.advanced.cashier_pin_policy.label'
                )
              "
              :disabled="readonly"
            />
          </el-form-item>

          <!-- Salesperson Policy -->
          <el-form-item prop="salesman_pin_policy" class="ml-6">
            <el-switch
              v-model="form.staff.salesman_pin_policy"
              active-value="before"
              inactive-value="none"
              :active-text="
                $t(
                  'pages.branches.edit.sections.advanced.salesman_pin_policy.label'
                )
              "
              :disabled="readonly"
            />
          </el-form-item>

          <!-- Cash handling -->
          <th-input-title
            :title="
              $t('pages.branches.edit.sections.advanced.cash_handling.title')
            "
          />

          <!-- Daily float -->
          <el-form-item class="ml-6 mt-2 mb-0 mb-xs-4" prop="daily_float">
            <th-input-title
              :title="
                $t('pages.branches.edit.sections.advanced.daily_float.label')
              "
              :info="
                $t('pages.branches.edit.sections.advanced.daily_float.help')
              "
            />
            <div
              v-for="(suggestion, index) in form.financials
                .keep_change_suggestion"
              :key="index"
            >
              <th-currency-input
                v-model="suggestion.amount"
                :currency="suggestion.currency"
                class="max-w-xs"
                data-testid="daily-float"
                :readonly="readonly"
              />
            </div>
          </el-form-item>
        </el-col>

        <!-- Center -->
        <el-col :lg="8" :md="12">
          <th-input-title
            :title="
              $t(
                'pages.branches.edit.sections.advanced.print_extended_information'
              )
            "
            class="mb-2"
          />

          <receipt-wrapper>
            <!-- Salesperson policy -->
            <el-form-item
              :label="
                $t(
                  'pages.branches.edit.form.properties.print_salesman_policy.label'
                )
              "
              prop="print_salesman_policy"
            >
              <el-select
                id="print_salesman_policy"
                v-model="
                  form.receipts.configurable_receipt.customer.print_salesperson
                    .header.style
                "
                :disabled="readonly"
                data-testid="print_salesman_policy"
                class="w-full"
                :placeholder="$t('common.interactions.selects.placeholder')"
                @change="handleSalespersonHeaderStyleChange"
              >
                <el-option
                  v-for="item in policyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <!-- Cashier policy -->
            <el-form-item
              :label="
                $t(
                  'pages.branches.edit.form.properties.print_cashier_policy.label'
                )
              "
              prop="print_cashier_policy"
            >
              <el-select
                id="print_cashier_policy"
                v-model="
                  form.receipts.configurable_receipt.customer
                    .print_cashier_policy
                "
                data-testid="print_cashier_policy"
                :placeholder="$t('common.interactions.selects.placeholder')"
                :disabled="readonly"
                class="w-full"
              >
                <el-option
                  v-for="item in policyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <hr class="my-2 border-dashed" />

            <!-- Product description -->
            <el-form-item prop="print_product_description" class="mb-0">
              <el-switch
                id="print_product_description"
                v-model="
                  form.receipts.configurable_receipt.customer
                    .print_product_description
                "
                :disabled="readonly"
                :active-text="
                  $t(
                    'pages.branches.edit.form.properties.print_product_description.label'
                  )
                "
              />
            </el-form-item>

            <!-- Product group -->
            <el-form-item prop="print_product_group" class="mb-0">
              <el-switch
                id="print_product_group"
                v-model="
                  form.receipts.configurable_receipt.customer
                    .print_product_group
                "
                :disabled="readonly"
                :active-text="
                  $t(
                    'pages.branches.edit.form.properties.print_product_group.label'
                  )
                "
              />
            </el-form-item>

            <!-- Customer information -->
            <el-form-item prop="print_customer_information" class="mb-0">
              <el-switch
                id="print_customer_information"
                v-model="printCustomerInformation"
                :disabled="readonly"
                :active-text="
                  $t('pages.branches.edit.form.properties.print_customer.label')
                "
              />
            </el-form-item>

            <div v-if="printCustomerInformation" class="flex flex-col ml-6">
              <!-- Name -->
              <el-checkbox
                v-model="
                  form.receipts.configurable_receipt.customer
                    .print_customer_name
                "
                :disabled="readonly"
                class="mb-2"
              >
                {{ $t('common.titles.name') }}
              </el-checkbox>

              <!-- Number -->
              <el-checkbox
                v-model="
                  form.receipts.configurable_receipt.customer
                    .print_customer_number
                "
                :disabled="readonly"
                class="mb-2"
              >
                {{ $t('pages.branches.edit.form.policy_options.number') }}
              </el-checkbox>

              <!-- Address -->
              <el-checkbox
                v-model="
                  form.receipts.configurable_receipt.customer
                    .print_customer_address
                "
                :disabled="readonly"
                class="mb-2"
              >
                {{ $t('pages.staff.form.headers.address') }}
              </el-checkbox>
            </div>

            <!-- Total discount -->
            <el-form-item prop="print_total_discount" class="mb-0">
              <el-switch
                id="print_total_discount"
                v-model="
                  form.receipts.configurable_receipt.customer
                    .print_total_discount
                "
                :disabled="readonly"
                :active-text="
                  $t(
                    'pages.branches.edit.form.properties.print_total_discount.label'
                  )
                "
              />
            </el-form-item>

            <!-- Print discount groups -->
            <el-form-item prop="print_discount_groups" class="mb-0">
              <el-switch
                id="print_discount_groups"
                v-model="
                  form.receipts.configurable_receipt.customer
                    .print_discount_groups
                "
                :disabled="readonly"
                :active-text="
                  $t(
                    'pages.branches.edit.form.properties.print_discount_groups.label'
                  )
                "
              />
            </el-form-item>
          </receipt-wrapper>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import ReceiptWrapper from './common/receipt-wrapper'

export default {
  components: {
    ReceiptWrapper
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      defaultCurrency: this.$store.getters['Config/getCurrentDefaultCurrency'],
      onRefundsOptions: [
        {
          value: 'always',
          label: this.$t(
            'pages.branches.edit.sections.advanced.on_refunds_always'
          )
        },
        {
          value: 'payout',
          label: this.$t(
            'pages.branches.edit.sections.advanced.on_refunds_payout'
          )
        }
      ],
      policyOptions: [
        {
          value: 'none',
          label: this.$t(
            'pages.branches.edit.sections.advanced.policy_options.none'
          )
        },
        {
          value: 'name',
          label: this.$t(
            'pages.branches.edit.sections.advanced.policy_options.name'
          )
        },
        {
          value: 'number',
          label: this.$t(
            'pages.branches.edit.sections.advanced.policy_options.number'
          )
        },
        {
          value: 'first_name',
          label: this.$t(
            'pages.branches.edit.sections.advanced.policy_options.first_name'
          )
        },
        {
          value: 'last_name',
          label: this.$t(
            'pages.branches.edit.sections.advanced.policy_options.last_name'
          )
        }
      ]
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },

    onRefundsSwitch: {
      get() {
        return this.showOnRefundSelect
      },
      set(value) {
        this.form.receipts.printing_behavior.merchant_triggers.on_refunds = value
          ? 'always'
          : 'never'
      }
    },

    showOnRefundSelect() {
      return (
        this.form.receipts.printing_behavior.merchant_triggers.on_refunds !==
        'never'
      )
    },

    printCustomerInformation: {
      get() {
        const {
          print_customer_name,
          print_customer_number,
          print_customer_address
        } = this.form.receipts.configurable_receipt.customer
        return (
          print_customer_name || print_customer_number || print_customer_address
        )
      },
      set(value) {
        this.form.receipts.configurable_receipt.customer = {
          ...this.form.receipts.configurable_receipt.customer,
          print_customer_name: value,
          print_customer_number: value,
          print_customer_address: value
        }
      }
    }
  },

  methods: {
    handleSalespersonHeaderStyleChange(value) {
      // Copy salesperson header style to item style
      this.form.receipts.configurable_receipt.customer.print_salesperson.item.style = value
    }
  }
}
</script>
