<template>
  <section v-loading="loading">
    <!-- General -->
    <branch-general
      ref="general"
      v-model="branch"
      :is-new="isNew"
      :readonly="isReadOnly"
    >
      <!-- Tenant info -->
      <tenant-info
        v-if="isMultiTenantMode"
        ref="tenant"
        v-model="currentConfiguration"
        data-testid="branch-tenant"
        :readonly="isReadOnly"
      />
    </branch-general>

    <!-- Address -->
    <addresses-input-v2
      ref="address"
      v-model="branch"
      data-testid="branch-address"
      :readonly="isReadOnly"
      :is-main-address-required="isMainAddressRequired"
    />

    <!-- Receipt configuration -->
    <branch-receipt-configuration
      ref="receipt-configuration"
      v-model="branch"
      :readonly="isReadOnly"
    />

    <!-- Advanced -->
    <branch-advanced
      v-if="currentConfiguration"
      ref="advanced"
      v-model="currentConfiguration"
      v-loading="loadingConfiguration"
      data-testid="branch-advanced"
      :readonly="isReadOnly"
    />

    <!-- Fiscalisation -->
    <branch-fiscalisation
      v-if="branch && !loading && $isFeatureEnabled('fiscalisation')"
      v-model="branch"
      :is-new="isNew"
      :readonly="isReadOnly"
      :validate-form="validate"
      @validate-form="(value) => (isMainAddressRequired = value)"
    />

    <!-- Datev -->
    <branch-datev
      v-if="isMultiTenantMode && $isFeatureEnabled('datev')"
      ref="datev"
      v-model="currentConfiguration"
      :readonly="isReadOnly"
      data-testid="branch-datev"
    />

    <!-- Custom -->
    <branch-custom
      v-if="hasDebugMode"
      ref="custom"
      v-model="branch"
      :readonly="isReadOnly"
      data-testid="branch-custom"
    />

    <!-- Opening Hours -->
    <!-- <branch-opening-hours
      v-if="$isFeatureEnabled('reservations')"
      ref="openingHours"
      v-model="currentConfiguration"
    /> -->
  </section>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'
import cloneDeep from 'clone-deep'
import compare from 'just-compare'
import pick from 'just-pick'
import { mapGetters } from 'vuex'
import { storeToRefs } from 'pinia'
import { getDefaultCurrency } from '@/utils/general'
import { replaceEmptyPropsWithNull } from '@/utils/objects'
import onboardingMixin from '@/mixins/onboarding'
import BranchGeneral from './branch-general'
import BranchReceiptConfiguration from './branch-receipt-configuration'
import BranchFiscalisation from './branch-fiscalisation'
import BranchAdvanced from './branch-advanced'
import BranchCustom from './branch-custom'
import BranchDatev from './branch-datev'
import BranchOpeningHours from './branch-opening-hours.vue'
import TenantInfo from './general/tenant-info'
import AddressesInputV2 from '@/components/tillhub/addresses-input-v2'
import {
  getConfiguration,
  getBranchDiffConfiguration,
  deepMergeConfigurations
} from './helpers'
import { isUnifiedCommerce } from '@/constants/index'
import { useBranchesStore } from '@/store/branches'

export default {
  components: {
    BranchGeneral,
    BranchReceiptConfiguration,
    BranchFiscalisation,
    BranchAdvanced,
    BranchCustom,
    BranchDatev,
    BranchOpeningHours,
    TenantInfo,
    AddressesInputV2
  },
  setup() {
    const { onboardingConfirmed, modifyOnboarding } = onboardingMixin()
    const branchesStore = useBranchesStore()
    const { branchesCount } = storeToRefs(branchesStore)

    return {
      onboardingConfirmed,
      modifyOnboarding,
      branchesStore,
      branchesCount
    }
  },
  data() {
    return {
      loading: false,
      isMainAddressRequired: false,
      branch: {
        // General
        name: null,
        branch_number: null,
        currency_default: null,
        cost_center: null,
        external_custom_id: null,
        // Address
        addresses: [],
        // Receipt configuration
        images: null,
        receipt_footer_images: null,
        receipt_header: null,
        receipt_footer: null,
        // Fiscalisation
        signing_configuration: null,
        // Custom
        metadata: {},
        custom_properties: null
      },
      loadingConfiguration: false,
      defaultConfiguration: getConfiguration({
        currency: getDefaultCurrency()
      }),
      branchConfiguration: {},
      currentConfiguration: getConfiguration({ currency: getDefaultCurrency() })
    }
  },

  computed: {
    ...mapGetters({
      hasDebugMode: 'Config/hasGlobalDataMode',
      navigationAfterCreation: 'Config/getNavigationAfterCreation',
      clientAccountConfiguration: 'Config/getClientAccountConfiguration',
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),

    isNew() {
      return !this.$route.params.id
    },

    isReadOnly() {
      return !this.isNew && isUnifiedCommerce()
    },

    branchId() {
      return this.$route.params.id
    },

    isMultiTenantMode() {
      return (
        safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'metadata.fiskaly_mode'
        ) === 'multitenant'
      )
    },

    mergedConfiguration() {
      return deepMergeConfigurations(
        this.defaultConfiguration, //default configuration
        this.clientAccountConfiguration, //client account configuration
        this.branchConfiguration //branch configuration
      )
    },

    diffConfiguration() {
      return getBranchDiffConfiguration(
        this.mergedConfiguration, //merged configuration
        this.currentConfiguration //current branch configuration
      )
    }
  },
  watch: {
    mergedConfiguration(newValue, oldValue) {
      if (!compare(newValue, oldValue)) {
        this.setCurrentConfiguration(newValue)
      }
    }
  },

  async mounted() {
    // Set default currency
    this.branch.currency_default = this.defaultCurrency

    if (this.branchId) {
      // Fetch branch
      await this.fetchBranch(this.branchId)
    } else {
      // Set pristine form
      this.pristineForm = this.$deepClone(this.branch)
    }
  },
  methods: {
    // ----------------- Fetch branch -----------------
    async fetchBranch() {
      this.loading = true
      try {
        const { data = {} } = await th.branches().get(this.branchId)
        if (data.id) {
          this.branch = this.parseBranchData(data)
          this.pristineForm = this.$deepClone(this.branch)
          await this.fetchConfiguration()
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.branch.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },

    parseBranchData(data) {
      //initialize addresses to empty array, and set address type to 'local' as default
      const addresses = (data.addresses || []).map((address) => {
        const type = address.type || 'local'
        return { ...address, type }
      })

      return { ...data, addresses }
    },

    handleAddresses(payload) {
      const localAddr = payload.addresses.find((a) => a.type === 'local')
      const { postal_code, region, locality, street_number, street } = localAddr
      if (!(postal_code || region || locality || street_number || street)) {
        return payload.addresses.filter((a) => a.type !== 'local')
      }
      return payload.addresses
    },

    handlePayload(branch) {
      const payload = replaceEmptyPropsWithNull({
        ...branch,
        branch_number: Number(branch.branch_number) || null // Must be a number
      })

      // Do not submit local address if empty
      payload.addresses = this.handleAddresses(payload)

      // Fix metadata payload
      if (payload.metadata === null) payload.metadata = {}

      return payload
    },

    // ----------------- Create -----------------
    async create() {
      const payload = this.handlePayload(this.branch)

      this.loading = true
      try {
        const { data = {} } = await th.branches().create(payload)

        // Modify onboarding, in case the step is not confirmed yet
        if (!this.onboardingConfirmed('branches')) {
          await this.modifyOnboarding('branches')
        }

        if (data.id) {
          // Nofify user
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.branch.singular')
            })
          })

          // Update branches store count
          if (this.branchesCount === 0) this.updateBranchesCount()

          // Update pristine form
          this.pristineForm = this.$deepClone(this.branch)

          // Submit branch group
          this.$refs.general.$refs.branchGroup.submitForm(data.id)

          // Submit configurations
          await this.submitConfiguration(data.id)

          // Navigate to edit/all branches view
          if (this.navigationAfterCreation === 'edit') {
            this.$router.push({
              name: 'resources-branches-edit',
              params: { id: data.id }
            })
          } else {
            this.$router.push({ name: 'resources-branches' })
          }
        }
      } catch (err) {
        this.checkBranchNumberError(err)
        // Show error
        return this.$logException(err, {
          message: this.$t('common.error.action.create.single', {
            resource: this.$t('common.resource.branch.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },

    // ----------------- Update -----------------
    async update() {
      const payload = this.handlePayload(this.branch)

      // Remove unwanted fields
      delete payload.id
      delete payload.created_at
      delete payload.updated_at
      delete payload.insert_id
      delete payload.custom_id
      delete payload.configurations
      delete payload.fa_account_number
      // @diyan temporary fix https://unz.atlassian.net/browse/UNTIL-8056
      delete payload.shift_plan
      delete payload.shift_plan_enabled

      this.loading = true
      try {
        const { data = {} } = await th.branches().put(this.branch.id, payload)

        // Modify onboarding, in case the step is not confirmed yet
        if (!this.onboardingConfirmed('branches')) {
          await this.modifyOnboarding('branches')
        }

        if (data.id) {
          // Notify user
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.update.single', {
              resource: this.$t('common.resource.branch.singular')
            })
          })

          this.branch = data

          // Update pristine form
          this.pristineForm = this.$deepClone(this.branch)

          // Submit branch group
          this.$refs.general.$refs.branchGroup.submitForm(data.id)

          // Submit configurations
          await this.submitConfiguration(data.id)
        }
      } catch (err) {
        this.checkBranchNumberError(err)
        return this.$logException(err, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.branch.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },

    // ----------------- Delete -----------------
    async delete() {
      try {
        await th.branches().delete(this.branch.id)

        // Remove from branch group
        this.$refs.general.$refs.branchGroup.handleDelete(this.branchId)

        // Remove configuration
        await this.deleteConfiguration()

        this.updateBranchesCount()

        // Notify user
        const successMessage = this.$t('common.success.action.delete.single', {
          resource: this.$t('common.resource.branch.singular')
        })
        this.$message({
          type: 'success',
          message: successMessage
        })

        // Go to branches
        this.handleCancel()
      } catch (err) {
        // Show error
        const errorMessage = this.$t('common.error.action.delete.single', {
          resource: this.$t('common.resource.branch.singular')
        })
        const errorMessageDescription = this.$t(
          'pages.branches.create.error.delete_fail.description'
        )
        this.$logException(err, {
          message: `${errorMessage}: ${errorMessageDescription}`
        })
      }
    },

    // ----------------- Check if the error comes from a already used branch number -----------------
    checkBranchNumberError(err) {
      const errorMsg = safeGet(err, 'properties.error.response.data.msg', '')
      // duplicate key value violates unique constraint \"branch_number_unique\"
      if (errorMsg.includes('unique')) {
        this.$refs.general.branchNumberError = true
        this.$refs.general.validate()
      }
    },

    // ----------------- Fetch configuration -----------------
    async fetchConfiguration() {
      this.loadingConfiguration = true
      try {
        const { data = [] } = await th
          .configurations()
          .getAll({ owner: this.branchId })

        this.branchConfiguration = data?.[0] ?? {}

        if (!data || !data[0]) {
          throw new Error(
            `configurations-fetch: configurations for branch ${this.branch.name} ${this.branch.branch_number} does not exist. A new configuration will be created in the next branch update.`
          )
        }
      } catch (err) {
        this.$log.warn(err)
      } finally {
        this.loadingConfiguration = false
      }
    },

    setCurrentConfiguration(currentConfiguration) {
      this.currentConfiguration = cloneDeep(currentConfiguration)
      this.pristineConfiguration = cloneDeep(currentConfiguration)
    },

    // ----------------- Submit configuration -----------------
    async submitConfiguration(branchId) {
      return this.branchConfiguration.id
        ? this.updateConfiguration()
        : this.createConfiguration(branchId)
    },

    // ----------------- Create configuration -----------------
    async createConfiguration(branchId) {
      try {
        const { data = {} } = await th.configurations().create({
          ...this.diffConfiguration,
          owner: branchId,
          name: this.branch.name || `Branch Config ${branchId}`
        })

        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.configuration.singular')
            })
          })
          this.branchConfiguration = data
        }
      } catch (err) {
        const errorMessage = this.$t('common.error.action.create.single', {
          resource: this.$t('common.resource.configuration.singular')
        })
        return this.$logException(err, { message: errorMessage })
      }
    },

    // ----------------- Update configuration -----------------
    async updateConfiguration() {
      try {
        const { data = {} } = await th
          .configurations()
          .put(this.branchConfiguration.id, this.diffConfiguration)
        this.branchConfiguration = data
      } catch (err) {
        const errorMessage = this.$t(
          'pages.branches.edit.form.receipt_configurations.messages.edit_fail'
        )
        return this.$logException(err, { message: errorMessage })
      }
    },

    // ----------------- Delete configuration -----------------
    async deleteConfiguration() {
      try {
        await th.configurations().delete(this.branchConfiguration.id)

        const successMessage = this.$t('common.success.action.delete.single', {
          resource: this.$t('common.resource.configuration.singular')
        })
        this.$message({
          type: 'success',
          message: successMessage
        })
      } catch (err) {
        const errorMessage = this.$t('common.error.action.delete.single', {
          resource: this.$t('common.resource.configuration.singular')
        })
        this.$logException(err, { message: errorMessage })
      }
    },

    // ----------------- Validate -----------------
    async validate() {
      const generalValid = await this.$refs.general.validate()
      const addressValid = await this.$refs.address.validate()
      const datevValid = this.$refs.datev
        ? await this.$refs.datev.validate()
        : true
      const customValid = this.$refs.custom
        ? this.$refs.custom.validate()
        : true
      const tenantValid = this.$refs.tenant
        ? await this.$refs.tenant.validate()
        : true

      const openingHoursValid = this.$refs.openingHours
        ? await this.$refs.openingHours.validate()
        : true

      return (
        generalValid &&
        addressValid &&
        datevValid &&
        customValid &&
        tenantValid &&
        openingHoursValid
      )
    },

    // ----------------- Handle submit -----------------
    async handleSubmit() {
      if (!(await this.shouldWarnOnRiskyChange())) return

      const valid = await this.validate()
      if (!valid) {
        return this.$message({
          type: 'warning',
          message: this.$t('common.forms.message.invalid_inputs')
        })
      }

      if (this.isNew) {
        await this.create()
      } else {
        await this.update()
      }
    },

    // ----------------- Handle cancel -----------------
    handleCancel() {
      this.$router.push({ name: 'resources-branches' })
    },

    // ----------------- Handle reset -----------------
    handleReset() {
      this.branch = cloneDeep(this.pristineForm)
      this.currentConfiguration = cloneDeep(this.pristineConfiguration)
      this.$refs.custom.handleReset()
    },

    // ----------------- Handle delete -----------------
    async handleDelete() {
      const confirm = await this.$askToDelete(
        this.branch.name || this.branch.id
      )
      if (confirm) this.delete()
    },

    // ----------------- Check DATEV change -----------------
    async shouldWarnOnRiskyChange() {
      if (!this.pristineConfiguration?.datev) return true
      const skrChanged =
        this.configuration.datev['SKR'] !==
        this.pristineConfiguration.datev['SKR']
      const accountNumberLengthChanged =
        this.configuration.datev['Sachkonten-nummernlänge'] !==
        this.pristineConfiguration.datev['Sachkonten-nummernlänge']

      // skr and account_number_length are data that the user should be very careful not to change after it being set up, because of legal reasons.
      // currently we would just warn the user whenever these two fields are changed, but we're not preventing the user from making mistakes, but we do warn them.
      // more context: https://tillhub.atlassian.net/browse/DAS-734
      const shouldWarn = skrChanged || accountNumberLengthChanged
      if (!shouldWarn) return true

      try {
        await this.$confirm(
          this.$t(
            'pages.settings.accounting.datev.dangerous_fields_change.warning.content'
          ),
          this.$t('common.titles.warning'),
          {
            confirmButtonText: this.$t('common.interactions.buttons.ok'),
            cancelButtonText: this.$t('common.interactions.buttons.cancel'),
            type: 'warning'
          }
        )
        return true
      } catch {
        return false
      }
    },

    updateBranchesCount() {
      this.branchesStore.checkBranchesCount()
    },

    // ----------------- Merge -----------------
    /**
     * Deep merge two objects
     * @example
     * // returns {a:"A", b:{ c:"C", d:"D", e:"E" }}
     * merge({a:"A", b:{ c:"C", d:"D" }}, {a:"A", b:{ e:"E" }});
     */
    deepMerge(objFrom, objTo) {
      return Object.keys(objFrom).reduce(
        (merged, key) => {
          merged[key] =
            objFrom[key] instanceof Object && !Array.isArray(objFrom[key])
              ? this.deepMerge(objFrom[key], merged[key] ?? {})
              : merged[key]
          return merged
        },
        { ...objTo }
      )
    }
  }
}
</script>
