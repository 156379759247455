<template>
  <el-form
    ref="form"
    :model="form"
    :rules="!readonly ? rules : null"
    class="-mb-6"
  >
    <hr class="my-6" />

    <el-row :gutter="20">
      <el-col :lg="8" :sm="24">
        <!-- Company name -->
        <el-form-item
          prop="company_name"
          :label="$t('pages.settings.account.edit.company_name.title')"
        >
          <el-input
            id="company_name"
            v-model="company_name"
            :placeholder="$t('pages.settings.account.edit.company_name.title')"
            :readonly="readonly"
          />
        </el-form-item>
      </el-col>

      <el-col :lg="8" :sm="24">
        <!-- VAT ID -->
        <el-form-item
          prop="vat_id"
          :label="$t('pages.settings.account.edit.vat_id.title')"
        >
          <el-input
            id="vat_id"
            v-model="vat_id"
            :placeholder="$t('pages.settings.account.edit.vat_id.title')"
            :readonly="readonly"
          />
        </el-form-item>
      </el-col>

      <el-col :lg="4" :sm="24">
        <!-- Tax Number -->
        <el-form-item
          prop="tax_number"
          :label="$t('pages.settings.account.edit.tax_number.title')"
        >
          <el-input
            id="tax_number"
            v-model="tax_number"
            :placeholder="$t('pages.settings.account.edit.tax_number.title')"
            :readonly="readonly"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import get from 'just-safe-get'
import set from 'just-safe-set'

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      rules: {
        company_name: [
          {
            required: true,
            message: this.$t(
              'pages.settings.account.edit.rules.company_name.required'
            ),
            trigger: 'blur'
          }
        ],
        vat_id: [
          {
            required: true,
            message: this.$t(
              'pages.settings.account.edit.rules.vat_id.required'
            ),
            trigger: 'blur'
          }
        ],
        tax_number: [
          {
            required: true,
            message: this.$t(
              'pages.settings.account.edit.rules.tax_number.required'
            ),
            trigger: 'blur'
          }
        ]
      }
    }
  },

  computed: {
    form() {
      return {
        company_name: this.company_name,
        vat_id: this.vat_id,
        tax_number: this.tax_number
      }
    },

    company_name: {
      get() {
        return get(this.modelValue, 'tenant.company_name') || null
      },
      set(val) {
        this.updateForm('company_name', val)
      }
    },

    vat_id: {
      get() {
        return get(this.modelValue, 'tenant.vat_id') || null
      },
      set(val) {
        this.updateForm('vat_id', val)
      }
    },

    tax_number: {
      get() {
        return get(this.modelValue, 'tenant.tax_number') || null
      },
      set(val) {
        this.updateForm('tax_number', val)
      }
    },

    isMultiTenantMode() {
      return (
        get(
          this.$store.state.Config.clientAccountConfiguration,
          'metadata.fiskaly_mode'
        ) === 'multitenant'
      )
    }
  },

  methods: {
    updateForm(field, val) {
      const tenantConfig = { ...(this.modelValue?.tenant || {}) }
      set(tenantConfig, field, val || null)
      this.$emit('update:modelValue', {
        ...this.modelValue,
        tenant: tenantConfig
      })
    },

    async validate() {
      if (!this.isMultiTenantMode) return true
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
