<template>
  <th-page-wrapper
    class="branch-edit"
    color="var(--font-color)"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <branch-form ref="form" />
  </th-page-wrapper>
</template>

<script>
import BranchForm from './components/form.vue'

export default {
  components: {
    BranchForm
  },

  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'resources:branches',
        returnedPath: { name: 'resources-branches' },
        isNew: this.isNew
      }
    }
  },

  methods: {
    async handleSubmit() {
      await this.$refs.form.handleSubmit()
      this.$store.dispatch('Config/setBranchCurrencies', { vm: this })
    },

    async handleDelete() {
      await this.$refs.form.handleDelete()
      this.$store.dispatch('Config/setBranchCurrencies', { vm: this })
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0px 2rem;
  background: white;
}

.branch-edit :deep(.content) {
  padding: 2rem;
}

@media (max-width: 640px) {
  .branch-edit :deep(.content) {
    padding: 0.5rem;
  }
}
</style>
