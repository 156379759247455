<template>
  <section class="receipt-wrapper p-6">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'ReceiptWrapper'
}
</script>

<style scoped>
.receipt-wrapper {
  position: relative;
  box-shadow: 1px 1px 4px 0px #00000038;
}

.receipt-wrapper:after,
.receipt-wrapper:before {
  background-image: linear-gradient(135deg, #ffffff 0.5rem, transparent 0),
    linear-gradient(-135deg, #fff 0.5rem, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 13px;
  content: '';
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 8px;
}

.receipt-wrapper:before {
  transform: rotate(180deg);
  bottom: unset;
  top: -2px;
}
</style>
