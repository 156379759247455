<template>
  <el-popover placement="top" width="400" trigger="click">
    <div class="whitespace-pre-line" v-text="targetData" />
    <template #reference>
      <el-button icon="View" class="px-2" />
    </template>
  </el-popover>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: false,
      default: undefined
    },
    type: {
      type: String,
      required: false,
      default: undefined
    }
  },

  computed: {
    targetData() {
      const data = []
      if (
        this.type === 'fiskaltrust' &&
        this.data &&
        Array.isArray(this.data.ftSignatures)
      ) {
        this.data.ftSignatures
          .filter((item) => item && [1].includes(item.ftSignatureFormat))
          .forEach((item) => {
            data.push(item.caption)
            data.push(item.data)
            data.push('----')
          })
      }
      return data.length ? data.join('\n') : this.$t('common.data.no_data')
    }
  }
}
</script>
