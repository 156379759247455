<template>
  <el-form-item
    :label="$t('pages.branches.edit.form.properties.branch_group.label')"
    prop="branch_group"
  >
    <el-select
      id="branch_group"
      v-model="selected"
      :disabled="readonly"
      class="w-full"
      multiple
      :placeholder="$t('common.interactions.selects.placeholder')"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </el-form-item>
</template>

<script>
import th from '@tillhub/javascript-sdk'

export default {
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      selected: [],
      branchGroupData: {},
      resources: {},
      pristine: []
    }
  },

  computed: {
    isNew() {
      return !this.$route.params.id
    },
    options() {
      return (
        this.resources.branchGroups &&
        this.resources.branchGroups.map((group) => {
          return {
            value: group.id,
            label: group.name || '-'
          }
        })
      )
    }
  },

  created() {
    this.fetchResources()

    if (!this.isNew) {
      this.fetchInitialValues()
    }
  },

  methods: {
    /**
     * Fetches all the branch groups which are linked to the particular branch.
     * Also sets the pristine values and initial user-selection.
     */
    async fetchInitialValues() {
      try {
        const resources = await this.$resourceFetch({
          resource: 'branchGroups',
          query: { branch: this.$route.params.id }
        })

        if (resources.branchGroups?.length) {
          const groupIds = resources.branchGroups.map((bGroup) => bGroup.id)
          this.pristine = [...groupIds]
          this.setSelectedGroups(groupIds)
        }
      } catch (err) {
        return this.$logException(err, {
          trackError: false,
          message: err.message || err
        })
      }
    },
    /**
     * Set the selected branches groups from the array of ids(Strings)
     *
     * @param {Array} groupIds
     */
    setSelectedGroups(groupIds = []) {
      this.selected = [...groupIds]
    },
    /**
     * Fetches all the branch groups and their data, in order to display them in the dropdown options.
     */
    async fetchResources() {
      try {
        this.resources = await this.$resourceFetch('branchGroups')
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    /**
     * On form submit compare pristine values and user-selected values to determine if a branch should be added to or removed from a branch group.
     *
     * @param {String} branch - the uuid of the current branch
     */
    async submitForm(branch) {
      // if a branch group is included in initial (pristine) values, but not in the user-selected branch groups, then remove the branch from the branch group
      if (this.pristine) {
        this.pristine.forEach((branchGroup) => {
          const shouldRemove = !this.selected.includes(branchGroup)

          if (shouldRemove) {
            this.removeBranch({ branch, branchGroup })
          }
        })
      }

      // if a branch group is included in the user-selected branch groups, but not in the initial (pristine) values, then add the branch to the branch group
      if (this.selected) {
        this.selected.forEach((branchGroup) => {
          const shouldAdd = !this.pristine.includes(branchGroup)
          if (shouldAdd) {
            this.addBranch({ branch, branchGroup })
          }
        })
      }
    },
    /**
     * A helper function to find the branch group data in the resources and then return branchGroup.branches.
     * It handles cases like missing resource data and invalid branch group id
     *
     * @param {String} branchGroup
     */
    findCurrentBranches(branchGroup) {
      if (!branchGroup) {
        const message = this.$t(
          'pages.branches.edit.sections.general.group.errors.invalid_group'
        ) // 'Invalid branch group'
        return this.$logException(new Error(message), { message })
      }

      if (!this.resources.branchGroups || !this.resources.branchGroups.length)
        return null

      const branchGroupData = this.resources.branchGroups.find(
        (group) => group.id === branchGroup
      )

      return branchGroupData && branchGroupData.branches
    },
    /**
     * Dedupe branches in a branch group just to add a data cleaning step and add the branch
     *
     * @param {Object} - branchGroupId and branchID
     */
    async addBranch({ branchGroup: branchGroupId, branch: branchId }) {
      const currentBranches = this.findCurrentBranches(branchGroupId)

      let uniqueBranches

      if (currentBranches) {
        uniqueBranches = new Set(currentBranches || [])
        uniqueBranches.add(branchId)
        uniqueBranches = Array.from(uniqueBranches)
      } else {
        uniqueBranches = [branchId]
      }

      try {
        await th.branchGroups().put(branchGroupId, { branches: uniqueBranches })
      } catch (err) {
        return this.$logException(err, { message: err.message || err })
      }

      this.pristine.push(branchGroupId)
    },
    /**
     * Dedupe branches in a branch group just to add a data cleaning step and remove the branch
     *
     * @param {Object} - branchGroupId and branchID
     */
    async removeBranch({ branchGroup: branchGroupId, branch: branchId }) {
      const currentBranches = this.findCurrentBranches(branchGroupId)

      if (!currentBranches) return

      let uniqueBranches

      uniqueBranches = new Set(currentBranches || [])
      uniqueBranches.delete(branchId)
      uniqueBranches = Array.from(uniqueBranches)

      try {
        await th.branchGroups().put(branchGroupId, { branches: uniqueBranches })
      } catch (err) {
        return this.$logException(err, { message: err.message || err })
      }

      const index = this.pristine.findIndex(
        (branchGroup) => branchGroup === branchGroupId
      )
      this.pristine.splice(index, 1)
    },
    /**
     * When a branch is deleted, it should be delete from all the branch groups as well.
     *
     * @param {String} branchId
     */
    async handleDelete(branchId) {
      this.pristine.forEach(async (branchGroup) => {
        this.removeBranch({ branch: branchId, branchGroup })
      })
    },
    /**
     * When a branch is reset, if its new, then deselect all, otherwise set the values of the unmodified branch.
     *
     */
    resetData() {
      this.setSelectedGroups(this.isNew ? [] : this.pristine)
    }
  }
}
</script>
