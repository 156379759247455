<template>
  <el-form ref="form" class="lg:-mb-4" @submit.prevent="initFiscalization">
    <el-row :gutter="20">
      <el-col :lg="8" :md="12">
        <!-- Fiskally registration key -->
        <el-form-item
          prop="key"
          :label="$t('pages.branches.edit.form.more.sections.fiscally.lable')"
        >
          <el-input
            id="key"
            v-model="licenseKey"
            :type="fiscalyInputType"
            :placeholder="
              hasFiscalyConfig || licenseKeySet ? '****************' : ''
            "
            :readonly="readonly"
          />
        </el-form-item>
      </el-col>
      <el-col :lg="8">
        <!-- initFiscalization -->
        <el-button
          v-loading="loading"
          type="primary"
          native-type="submit"
          plain
          class="h-10 mt-6"
          :disabled="loading || readonly || !licenseKey"
          v-text="$t('common.interactions.buttons.save')"
        />
      </el-col>
      <el-col>
        <p
          class="th-wrapper-subtitle w-full text-sm mt-2 text-th-color-header italic"
        >
          {{ $t('pages.branches.edit.form.more.sections.fiscally.title') }}

          <a
            target="_blank"
            href="https://help.unzer.com/kb/guide/de/fiskalisierung-in-osterreich-U9wcUCpOFS/Steps/3007196"
            class="text-th-primary"
            v-text="
              $t(
                'pages.branches.edit.form.more.sections.fiscally.more_information'
              )
            "
          />
        </p>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import { mapGetters } from 'vuex'
import { countries } from '@/utils/address'

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },

    isNew: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    validateForm: {
      type: Function,
      default: () => true
    }
  },
  data() {
    return {
      fiskalKey: null,
      loading: false,
      licenseKey: null,
      licenseKeySet: false
    }
  },
  computed: {
    ...mapGetters({
      clientConfiguration: 'Config/getClientAccountConfiguration'
    }),
    fiscalConfig() {
      return safeGet(this.modelValue, 'signing_configuration', {})
    },
    signatureType() {
      return safeGet(
        this.modelValue,
        'signing_configuration.signature_type',
        null
      )?.toLowerCase()
    },
    hasFiscalyConfig() {
      return this.signatureType === 'at_fiskaly'
    },
    hasFiskalyKey() {
      return this.hasFiscalyConfig
    },
    fiscalyInputType() {
      return this.hasFiscalyConfig && !this.licenseKey ? 'password' : 'text'
    },
    branchId() {
      return this.$route.params.id
    },
    organizationName() {
      return `${this.clientConfiguration.tenant.company_name} - ${this.modelValue.name}`
    },
    branchFiscalisationOptions() {
      const {
        street,
        street_number,
        country,
        postal_code: zip,
        locality: town
      } = safeGet(this.clientConfiguration, 'tenant.address', {})

      const countryCode = countries.find(({ iso }) => iso === country)?.iso3
      const {
        tid: fonParticipantId,
        benid: fonUserId,
        pin: fonUserPin
      } = safeGet(this.clientConfiguration, 'settings.fiscally.austria', {})

      return {
        provider: 'AT_FISKALY',
        payload: {
          branchId: this.branchId,
          organization: {
            name: this.organizationName,
            addressLine1: `${street} ${street_number}`,
            zip,
            town,
            countryCode
          },
          fon: {
            fonParticipantId,
            fonUserId,
            fonUserPin
          }
        }
      }
    }
  },
  watch: {
    licenseKey(newValue, oldValue) {
      if (!!newValue !== !!oldValue) {
        this.$emit('validate-form', !!newValue)
      }
    }
  },
  methods: {
    // ----------------- Submit -----------------
    async initFiscalization() {
      // chcek if branch form is valid
      if (!(await this.validateForm())) {
        return this.$message({
          type: 'warning',
          message: this.$t('common.forms.message.invalid_inputs')
        })
      }

      // check if fiscal configuration is valid
      if (!(await this.validateFiscalConfiguration())) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'pages.settings.fiscalization.austria.connection.missing_configuration'
          )
        })
      }

      try {
        this.loading = true
        const {
          data: { fiscalAccountId }
        } = await th.fiscalization().init(this.branchFiscalisationOptions)

        const {
          data: { status }
        } = await th
          .fiscalization()
          .setLicense(this.branchId, { licenseKey: this.licenseKey })

        if (fiscalAccountId && status) {
          this.fiskalKey = fiscalAccountId
          this.licenseKey = null
          this.licenseKeySet = true
          this.$message({
            type: 'success',
            message: this.$t(
              'pages.settings.fiscalization.austria.connection.succeeded'
            )
          })
        }
      } catch (err) {
        const errorMessage = this.$t(
          'pages.settings.fiscalization.austria.connection.failed'
        )
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },

    // ----------------- Validate -----------------
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },
    async validateFiscalConfiguration() {
      return new Promise((resolve) => {
        const {
          fonParticipantId,
          fonUserId,
          fonUserPin
        } = this.branchFiscalisationOptions.payload.fon

        resolve(this.branchId && fonParticipantId && fonUserId && fonUserPin)
      })
    }
  }
}
</script>
