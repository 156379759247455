<template>
  <th-wrapper
    :title="$t('pages.branches.edit.form.more.sections.datev_configuration')"
    collapsable
  >
    <el-form ref="form" :model="form" :rules="!readonly ? rules : null">
      <el-row :gutter="20">
        <el-col :xl="4" :lg="8" :md="12">
          <!-- Transit Account -->
          <el-form-item
            prop="transit_account"
            :label="$t('pages.settings.accounting.transit_account')"
          >
            <el-input
              id="transit_account"
              v-model="transit_account"
              :readonly="readonly"
              clearable
            />
          </el-form-item>
        </el-col>
      </el-row>

      <hr class="mb-6 mt-1" />

      <el-row :gutter="20">
        <el-col :xl="4" :lg="8" :md="12">
          <!-- Advisor -->
          <el-form-item
            prop="advisor_number"
            :label="$t(`pages.settings.accounting.datev.advisor_number.label`)"
          >
            <el-input
              id="advisor_number"
              v-model="advisor_number"
              :readonly="readonly"
              clearable
            />
          </el-form-item>

          <!-- Client -->
          <el-form-item
            prop="client_number"
            :label="$t(`pages.settings.accounting.datev.client_number.label`)"
          >
            <el-input
              id="client_number"
              v-model="client_number"
              :readonly="readonly"
              clearable
            />
          </el-form-item>

          <!-- Start of Business Yea -->
          <el-form-item
            prop="business_year"
            :label="$t(`pages.settings.accounting.datev.business_year.label`)"
          >
            <date-picker-input
              id="business_year"
              v-model="business_year"
              class="w-full"
              :date-format="dateFormat"
              :disabled="readonly"
            />
          </el-form-item>

          <!-- Account Number Length -->
          <el-form-item
            prop="account_number_length"
            :label="
              $t(`pages.settings.accounting.datev.account_number_length.label`)
            "
          >
            <el-select
              id="account_number_length"
              v-model="account_number_length"
              :disabled="readonly"
            >
              <el-option
                v-for="item in accountNumberLengthOptions"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>

          <!-- SKR -->
          <el-form-item
            prop="skr"
            class="mb-0"
            :label="$t(`pages.settings.accounting.datev.skr.label`)"
          >
            <el-select id="skr" v-model="skr" :disabled="readonly">
              <el-option
                v-for="item in skrOptions"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'
import parseDate from 'date-fns/parse'
import formatDate from 'date-fns/format'
import DatePickerInput from '@/components/inputs/date-picker'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'

export default {
  components: {
    DatePickerInput
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },

    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const appConfigStore = useAppConfigStore()
    const { featureConfig } = storeToRefs(appConfigStore)
    return { featureConfig }
  },
  data() {
    return {
      dateFormat: this.$date.getDateFormat(),
      accountNumberLengthOptions: ['3', '4', '5', '6', '7', '8', '9'],
      skrOptions: ['03', '04'],
      rules: {
        advisor_number: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          },
          {
            min: 1,
            max: 7,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 1,
              max: 7
            }),
            trigger: 'change'
          }
        ],
        client_number: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          },
          {
            min: 1,
            max: 5,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 1,
              max: 5
            }),
            trigger: 'change'
          }
        ],
        business_year: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ]
      }
    }
  },

  computed: {
    form() {
      return {
        transit_account: this.transit_account,
        advisor_number: this.advisor_number,
        client_number: this.client_number,
        business_year: this.business_year,
        account_number_length: this.account_number_length,
        skr: this.skr
      }
    },

    transit_account: {
      get() {
        return safeGet(this.modelValue, 'financials.fa_transit_account') || null
      },
      set(val) {
        const financialsConfig = { ...(this.modelValue?.financials || {}) }
        safeSet(financialsConfig, 'fa_transit_account', val || null)
        this.$emit('update:modelValue', {
          ...this.modelValue,
          financials: financialsConfig
        })
      }
    },

    advisor_number: {
      get() {
        return safeGet(this.modelValue, ['datev', 'Berater']) || null
      },
      set(val) {
        const datevConfig = { ...(this.modelValue?.datev || {}) }
        safeSet(datevConfig, 'Berater', val || null)
        this.$emit('update:modelValue', {
          ...this.modelValue,
          datev: datevConfig
        })
      }
    },

    client_number: {
      get() {
        return safeGet(this.modelValue, ['datev', 'Mandant']) || null
      },
      set(val) {
        const datevConfig = { ...(this.modelValue?.datev || {}) }
        safeSet(datevConfig, 'Mandant', val || null)
        this.$emit('update:modelValue', {
          ...this.modelValue,
          datev: datevConfig
        })
      }
    },

    business_year: {
      get() {
        const storedDate = safeGet(this.modelValue, ['datev', 'WJ-Beginn'])
        return storedDate
          ? parseDate(storedDate, 'yyyyMMdd', new Date()).toISOString()
          : null
      },
      set(val) {
        const datevConfig = { ...(this.modelValue?.datev || {}) }
        const newValue = val ? formatDate(new Date(val), 'yyyyMMdd') : null
        safeSet(datevConfig, 'WJ-Beginn', newValue)
        this.$emit('update:modelValue', {
          ...this.modelValue,
          datev: datevConfig
        })
      }
    },

    account_number_length: {
      get() {
        return (
          safeGet(this.modelValue, ['datev', 'Sachkonten-nummernlänge']) ||
          safeGet(this.$store.state.Config.clientAccountConfiguration, [
            'datev',
            'Sachkonten-nummernlänge'
          ]) ||
          null
        )
      },
      set(val) {
        const datevConfig = { ...(this.modelValue?.datev || {}) }
        safeSet(datevConfig, 'Sachkonten-nummernlänge', val)
        this.$emit('update:modelValue', {
          ...this.modelValue,
          datev: datevConfig
        })
      }
    },

    skr: {
      get() {
        return (
          safeGet(this.modelValue, ['datev', 'SKR']) ||
          safeGet(this.$store.state.Config.clientAccountConfiguration, [
            'datev',
            'SKR'
          ]) ||
          null
        )
      },
      set(val) {
        const datevConfig = { ...(this.modelValue?.datev || {}) }
        safeSet(datevConfig, 'SKR', val)
        this.$emit('update:modelValue', {
          ...this.modelValue,
          datev: datevConfig
        })
      }
    }
  },

  mounted() {
    // Set default date 1.1.YYYY
    if (!this.business_year) {
      const defaultDate = new Date()
      defaultDate.setDate(1)
      defaultDate.setMonth(0)
      this.business_year = defaultDate
    }
  },

  methods: {
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
