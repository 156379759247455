<template>
  <th-wrapper
    :title="$t('pages.branches.edit.sections.opening_hours.title')"
    collapsable
    collapsed-by-default
  >
    <div class="mb-4">
      <th-input-title class="p-0 flex items-center">
        <el-switch
          v-model="configuration.reservations.use_individual_opening_hours"
          class="mr-4"
        />

        {{
          $t(
            'pages.branches.edit.form.opening_hours.individual_opening_hours.label'
          )
        }}
      </th-input-title>
    </div>

    <opening-hours-form ref="formRef" v-model="openingHours" />
  </th-wrapper>
</template>

<script setup>
import { defineProps, defineExpose, ref, computed } from 'vue'
import OpeningHoursForm from '@/views/settings/sections/dashboard/reservations/components/opening-hours/opening-hours-form.vue'
import { getDefaultOpeningHours } from './helpers'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({})
  }
})

defineExpose({ validate })

const configuration = computed(() => props.modelValue)

const openingHours = computed({
  get: () =>
    configuration.value?.reservations?.opening_hours ||
    getDefaultOpeningHours(),
  set: (value) => {
    configuration.value.reservations.opening_hours = value
  }
})

const formRef = ref()

async function validate() {
  return formRef.value.validate()
}
</script>
