<template>
  <th-wrapper
    :title="$t('common.titles.general_info.title')"
    :subtitle="$t('pages.branches.edit.sections.general.subtitle')"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="!readonly ? rules : null"
      class="-mb-4"
    >
      <el-row :gutter="20">
        <!-- Branch name -->
        <el-col :lg="8" :sm="24">
          <el-form-item
            :label="$t('pages.balances.all.branch_name')"
            prop="name"
          >
            <el-input id="name" v-model="form.name" :readonly="readonly" />
          </el-form-item>
        </el-col>

        <!-- Branch number -->
        <el-col :lg="8" :sm="24">
          <el-form-item
            :label="
              $t('pages.branches.edit.form.properties.branch_number.label')
            "
            prop="branch_number"
          >
            <el-input
              id="branch_number"
              v-model="form.branch_number"
              :disabled="!isNew"
            />
          </el-form-item>
        </el-col>

        <!-- Branch currency -->
        <el-col :lg="4" :sm="24">
          <el-form-item
            :label="$t('pages.branches.edit.form.properties.currency.label')"
            for="currency_default"
            prop="currency_default"
          >
            <th-currency-select
              v-model="form.currency_default"
              data-testid="currency-select"
              add-class="w-full"
              :readonly="readonly"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- Cost center -->
        <el-col :lg="8" :sm="24">
          <el-form-item prop="cost_center">
            <th-input-title
              :title="
                $t('pages.branches.edit.form.properties.cost_center.label')
              "
              :info="
                $t(
                  'pages.registers.edit.form.properties.cost_center.datev.text'
                )
              "
            />
            <el-input
              id="cost_center"
              v-model="form.cost_center"
              data-testid="cost_center"
              :readonly="readonly"
            />
          </el-form-item>
        </el-col>

        <!-- Branch group -->
        <el-col :lg="8" :sm="24">
          <branch-group ref="branchGroup" :readonly="readonly" />
        </el-col>

        <!-- Custom ID -->
        <el-col :lg="4" :sm="24">
          <el-form-item
            :label="
              $t('pages.branches.edit.form.properties.external_custom_id.label')
            "
            prop="external_custom_id"
          >
            <el-input
              id="external_custom_id"
              v-model="form.external_custom_id"
              :readonly="readonly"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- Tenant info slot -->
    <slot />
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import BranchGroup from './general/branch-group.vue'

export default {
  components: {
    BranchGroup
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },

    isNew: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      checkExternalCustomIdTimeout: null,
      branchNumberError: false
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    rules() {
      return {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.branches.edit.form.validation.branch_name.required'
            ),
            trigger: 'blur'
          },
          {
            min: 3,
            message: this.$t('common.forms.rules.min_length', { length: 3 }),
            trigger: ['blur', 'change']
          },
          {
            max: 32,
            message: this.$t('common.forms.rules.max_length', { length: 32 }),
            trigger: ['blur', 'change']
          }
        ],
        currency_default: [
          {
            // Currency is required in edit mode, because when creating a branch via Endor, the currency is not set.
            // It's not an issue when creating a branch in the dashboard.
            // https://tillhub.atlassian.net/browse/DAS-1549
            required: !this.isNew,
            message: this.$t(
              'pages.branches.edit.form.validation.branch_number.required'
            ),
            trigger: 'blur'
          }
        ],
        branch_number: [
          {
            required: true,
            message: this.$t(
              'pages.branches.edit.form.validation.branch_number.required'
            ),
            trigger: 'blur'
          },
          {
            validator: this.checkBranchNumber,
            trigger: 'blur'
          },
          { validator: this.validateBranchNumberError, trigger: 'blur' }
        ],
        external_custom_id: [
          {
            validator: this.checkExternalCustomId,
            trigger: 'change'
          }
        ],
        cost_center: [
          {
            max: 128,
            message: this.$t('common.forms.rules.max_length', { length: 128 })
          }
        ]
      }
    }
  },

  watch: {
    'form.branch_number': function () {
      if (this.branchNumberError) this.branchNumberError = false
    }
  },

  methods: {
    checkExternalCustomId(rule, value, callback) {
      // Debounce check unique external id for 500ms
      clearInterval(this.checkExternalCustomIdTimeout)
      this.checkExternalCustomIdTimeout = setTimeout(async () => {
        if (value) {
          const response = await this.checkExternalIdUnique(value)
          callback(
            !response
              ? this.$t(
                  'pages.branches.edit.form.properties.external_custom_id.warning'
                )
              : undefined
          )
        } else {
          callback()
        }
      }, 500)
    },

    async checkExternalIdUnique(id) {
      const branch = this.$route.params.id || undefined
      try {
        const { data } = await th
          .branches()
          .getUniqueExternalId({ provided_id: id, branch })
        return !!data[0].external_custom_id
      } catch (error) {
        if (
          error.properties &&
          error.properties.name === 'ExternalCustomIdNotUnique'
        ) {
          return false
        } else {
          this.$logException(error, { trackError: false })
          return false
        }
      }
    },

    checkBranchNumber(rule, value, callback) {
      if (value === '' || !Number.isFinite(Number(value))) {
        callback(
          this.$t('pages.branches.edit.form.validation.branch_number.required')
        )
      } else if (value >= 2147483648) {
        // postgres stores branch_number as int4 (4 bytes), so the maximum is 2^31
        callback(
          this.$t(
            'pages.branches.edit.form.validation.branch_number.maximum_exceeded'
          )
        )
      } else {
        callback()
      }
    },

    validateBranchNumberError(rule, value, callback) {
      if (this.branchNumberError) {
        callback(
          new Error(
            this.$t('pages.branches.error.branch_number.message', {
              branch_number: this.form.branch_number
            })
          )
        )
      } else {
        callback()
      }
    },
    // ----------------- Validate -----------------
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
