<template>
  <th-wrapper
    :title="$t('pages.branches.edit.form.more.sections.fiscaly.title')"
    collapsable
    collapsed-by-default
    :disabled="isNew"
    :disabled-text="
      $t('pages.branches.edit.form.more.sections.signing.disabled')
    "
  >
    <!-- Fiscalisation -->
    <branch-fiscalisation-germany
      v-if="hasFiscalConfig"
      v-model="form.signing_configuration"
      class="mb-6"
      :is-new="isNew"
      :readonly="isReadOnly"
      data-testid="branch-fiscalisation"
    />

    <!-- Austrian Fiscalisation -->
    <branch-fiscalisation-austria
      v-model="form"
      :is-new="isNew"
      :readonly="isReadOnly"
      data-testid="branch-fiscalisation"
      :validate-form="validateForm"
      @validate-form="$emit('validate-form', $event)"
    />
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import BranchFiscalisationGermany from './branch-fiscalisation-germany'
import BranchFiscalisationAustria from './branch-fiscalisation-austria'

export default {
  components: {
    BranchFiscalisationGermany,
    BranchFiscalisationAustria
  },

  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },

    isNew: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    validateForm: {
      type: Function,
      default: () => true
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    hasFiscalConfig() {
      return (
        safeGet(
          this.modelValue,
          'signing_configuration.signature_type',
          null
        ) === 'fiskaltrust'
      )
    }
  }
}
</script>
