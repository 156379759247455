import cloneDeep from 'clone-deep'
import compare from 'just-compare'
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'

export const getConfiguration = ({ currency } = {}) =>
  cloneDeep({
    receipts: {
      configurable_receipt: {
        enabled: true,
        customer: {
          // customer receipt and merchant are the same for this
          print_cashier_policy: 'name', // name, number, none, first_name, last_name
          print_salesperson: {
            header: {
              // if its not printed schema requires this value to be null
              style: 'name', // number, first_name, last_name
              conditions: [
                // preset conditions like below - currently not user configurable
                'is_unique'
              ]
            },
            footer: null, // set null - we wont support this
            item: {
              // always set like this - if printing sales person
              style: 'name', // same as header!!
              conditions: ['not_unique']
            }
          },
          print_customer_address: true,
          print_customer_name: true,
          print_customer_number: true,
          print_product_description: true,
          print_product_group: false,
          print_total_discount: false
        },
        merchant: {
          // same object as for customer with same values here
          print_cashier_policy: 'name', // name, number, none, first_name, last_name
          print_salesperson: {
            header: {
              // if its not printed schema requires this value to be null
              style: 'name', // number, name
              conditions: [
                // preset conditions like below - currently not user configurable
                'is_unique'
              ]
            },
            footer: null, // set null - we wont support this
            item: {
              // always set like this - if printing sales person
              style: 'name', // same as header!!
              conditions: ['not_unique']
            }
          },
          print_customer_address: true,
          print_customer_name: true,
          print_customer_number: true,
          print_product_description: true,
          print_product_group: false,
          print_total_discount: false,

          // this is additional to merchant receipt
          print_branch_logo: false,
          print_branch_footer: false,
          print_branch_header: false
        }
      },
      printing_behavior: {
        merchant_main: false, // is the merchant receipt printed
        merchant_triggers: {
          has_tips: false,
          on_refunds: 'payout',
          signing_failed: true,
          terminal_payments: false
        }
      }
    },
    settings: {
      default_currency: null,
      revision_safety: {
        cancellation_receipt: true // is the cancellation printed or not
      }
    },
    // DATEV
    // datev: {
    //   Berater: null,
    //   Mandant: null,
    //   'WJ-Beginn': null,
    //   'Sachkonten-nummernlänge': null,
    //   SKR: null
    // },
    datev: {},
    // financials: {
    //   keep_change_suggestion: []
    // },
    financials: {
      fa_transit_account: null,
      keep_change_suggestion: [
        {
          amount: null,
          currency
        }
      ]
    },
    // Tenant
    // tenant: {
    //   company_name: null,
    //   vat_id: null,
    //   tax_number: null
    // }
    tenant: {},
    // Staff
    // staff: {
    //   salesman_pin_policy: null,
    //   cashier_pin_policy: null
    // }
    staff: {
      salesman_pin_policy: 'none',
      cashier_pin_policy: 'none'
    },
    reservations: {
      use_individual_opening_hours: false,
      opening_hours: null
    }
  })

// This function will return only the diff object between the default configuration and the current configuration
// @param {Object} defaultConfiguration - default configuration object
// @param {Object} currentConfiguration - current configuration object
// @returns {Object} - returns the diff object between the default configuration and the current configuration

export function getBranchDiffConfiguration(
  mergedConfiguration = {},
  currentConfiguration = {}
) {
  return Object.entries(flattenObject(currentConfiguration)).reduce(
    (diffConfiguration, [keyPath, currentValue]) => {
      const defaultValue = safeGet(mergedConfiguration, keyPath)
      if (!compare(defaultValue, currentValue)) {
        safeSet(diffConfiguration, keyPath, currentValue)
      }
      return diffConfiguration
    },
    {}
  )
}

// This function will return the merged a list of configurations by order of priority
// @param {Object} baseConfiguration - the first configuration is the base configuration the results will be merged into
// @param {...Object} list of configurations - configurations in ordered priority, low to high
// @returns {Object} - returns the merged configuration

export function deepMergeConfigurations(
  baseConfiguration = {},
  ...configurations
) {
  return configurations.reduce((mergedConfiguration, currentConfiguration) => {
    Object.entries(flattenObject(baseConfiguration)).forEach(
      ([keyPath, defaultValue]) => {
        const currentValue = safeGet(
          currentConfiguration,
          keyPath,
          defaultValue
        )
        safeSet(mergedConfiguration, keyPath, currentValue)
      }
    )
    return mergedConfiguration
  }, {})
}

// This function will return the flattened object of the configuration
// @param {Object} input - configuration object
// @param {String} keyName - key name
// @returns {Object} - returns the flattened object of path and value

export function flattenObject(input, keyName) {
  var result = {}
  for (const key in input) {
    const newKey = keyName ? `${keyName}.${key}` : key
    if (typeof input[key] === 'object' && !Array.isArray(input[key])) {
      result = { ...result, ...flattenObject(input[key], newKey) }
    } else {
      result[newKey] = input[key]
    }
  }
  return result
}

export function getDefaultOpeningHours() {
  return [
    {
      closed: false,
      open_to: '17:00',
      break_to: '13:00',
      day_index: 0,
      open_from: '09:00',
      break_from: '12:00'
    },
    {
      closed: false,
      open_to: '17:00',
      break_to: '13:00',
      day_index: 1,
      open_from: '09:00',
      break_from: '12:00'
    },
    {
      closed: false,
      open_to: '17:00',
      break_to: '13:00',
      day_index: 2,
      open_from: '09:00',
      break_from: '12:00'
    },
    {
      closed: false,
      open_to: '17:00',
      break_to: '13:00',
      day_index: 3,
      open_from: '09:00',
      break_from: '12:00'
    },
    {
      closed: false,
      open_to: '17:00',
      break_to: '13:00',
      day_index: 4,
      open_from: '09:00',
      break_from: '12:00'
    },
    {
      closed: true,
      open_to: '17:00',
      break_to: '13:00',
      day_index: 5,
      open_from: '09:00',
      break_from: '12:00'
    },
    {
      closed: true,
      open_to: '17:00',
      break_to: '13:00',
      day_index: 6,
      open_from: '09:00',
      break_from: '12:00'
    }
  ]
}
