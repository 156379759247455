<template>
  <th-wrapper
    :title="$t('pages.branches.edit.form.receipt_configurations.title')"
    collapsable
  >
    <el-form ref="form" :model="form" class="-mb-4">
      <el-row :gutter="20">
        <el-col :lg="8" :md="12">
          <!-- Receipt header -->
          <el-form-item
            :label="
              $t('pages.branches.edit.form.properties.receipt_header.label')
            "
            prop="receipt_header"
          >
            <el-input
              id="receipt_header"
              v-model="form.receipt_header"
              type="textarea"
              :placeholder="defaultHeader"
              :rows="6"
              :readonly="readonly"
            />
          </el-form-item>

          <!-- Receipt footer -->
          <el-form-item
            :label="
              $t('pages.branches.edit.form.properties.receipt_footer.label')
            "
            prop="receipt_footer"
          >
            <el-input
              id="receipt_footer"
              v-model="form.receipt_footer"
              type="textarea"
              :placeholder="defaultFooter"
              :rows="6"
              :readonly="readonly"
            />
          </el-form-item>
        </el-col>

        <el-col :lg="8" :md="12">
          <!-- Preview -->
          <el-form-item :label="$t('pages.document_designer.preview')">
            <receipt-wrapper class="preview py-8" style="min-height: 319px">
              <i>{{ $t('pages.document_designer.logo') }}</i>
              <br />
              <br />
              <p>{{ form.receipt_header || defaultHeader }}</p>
              <br />
              <hr />
              <br />
              <i>
                {{
                  $t(
                    'pages.branches.edit.form.receipt_configurations.preview.body'
                  )
                }}
              </i>
              <br />
              <hr />
              <br />
              <p>{{ form.receipt_footer || defaultFooter }}</p>
            </receipt-wrapper>
          </el-form-item>
        </el-col>

        <el-col :lg="8" :md="12">
          <!-- Branch image -->
          <el-form-item
            :label="$t('pages.branches.edit.form.properties.images.label')"
            prop="image"
          >
            <th-image-upload-v2
              id="image"
              v-model="form.images"
              resource="branches"
              fill-type="contain"
              image-key="1x"
              height="138px"
              icon="PictureRounded"
              :readonly="readonly"
              :title="
                $t(
                  'pages.products.edit.form.sections.standard.images.add_image'
                )
              "
            />
          </el-form-item>

          <!-- Receipt footer image -->
          <el-form-item
            :label="
              $t(
                'pages.branches.edit.form.properties.receipt-footer-image.label'
              )
            "
            prop="receipt_footer_images"
          >
            <th-image-upload-v2
              id="receipt_footer_images"
              v-model="form.receipt_footer_images"
              resource="branches"
              fill-type="contain"
              image-key="1x"
              height="138px"
              icon="PictureRounded"
              :title="
                $t(
                  'pages.products.edit.form.sections.standard.images.add_image'
                )
              "
              :readonly="readonly"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import ReceiptWrapper from './common/receipt-wrapper'

export default {
  components: {
    ReceiptWrapper
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },

    address() {
      return safeGet(
        this.$store.state.Config.clientAccountConfiguration,
        'tenant.address',
        {}
      )
    },

    branchAddress() {
      if (!this.form.addresses.length) return this.address
      if (
        !(
          this.form.addresses[0].locality ||
          this.form.addresses[0].postal_code ||
          this.form.addresses[0].region ||
          this.form.addresses[0].street ||
          this.form.addresses[0].street_number
        )
      ) {
        return this.address
      }
      return this.form.addresses[0]
    },

    defaultHeader() {
      const { street, street_number, postal_code, locality } =
        this.branchAddress || {}
      const name = this.form.name || this.$t('pages.balances.all.branch_name')
      return `${name}\n${street} ${street_number}\n${postal_code} ${locality}`
    },

    defaultFooter() {
      return this.$t(
        'pages.branches.edit.form.receipt_configurations.preview.footer'
      )
    }
  }
}
</script>

<style scoped>
.preview {
  line-height: 1.2;
  text-align: center;
}

.preview p {
  font-family: monospace;
  margin: 0;
  white-space: pre-wrap;
  display: block;
}

.preview i {
  opacity: 0.6;
  display: block;
}

.preview hr {
  border-style: dashed;
  margin: 0;
}
</style>
