<template>
  <th-wrapper
    :title="$t('pages.transactions.all.table.subtable.payment_types.custom')"
    collapsable
    collapsed-by-default
    @collapsed-changed="updateEditors"
  >
    <el-row :gutter="20">
      <el-col :lg="12" class="lg:mb-0 mb-6">
        <!-- Edit Metadata -->
        <th-input-title
          :title="$t('pages.branches.edit.form.more.sections.metadata')"
        />
        <div class="h-56">
          <json-editor
            v-if="showEditors"
            ref="metadataEditor"
            data-testid="metadataEditor"
            :json-input="form.metadata"
            :readonly="readonly"
            @jsonChanged="metadataChanged"
          />
        </div>
      </el-col>

      <el-col :lg="12">
        <!-- Edit custom properties -->
        <th-input-title
          :title="
            $t('pages.branches.edit.form.more.sections.custom_properties')
          "
        />
        <div class="h-56">
          <json-editor
            v-if="showEditors"
            ref="customPropertiesEditor"
            data-testid="customPropertiesEditor"
            :json-input="form.custom_properties"
            :readonly="readonly"
            @jsonChanged="customPropertiesChanged"
          />
        </div>
      </el-col>
    </el-row>
  </th-wrapper>
</template>

<script>
import JsonEditor from '@/components/json-editor'

export default {
  components: {
    JsonEditor
  },

  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showEditors: false
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },

  methods: {
    metadataChanged(value) {
      this.jsonChanged('metadata', value)
    },

    customPropertiesChanged(value) {
      this.jsonChanged('custom_properties', value)
    },

    jsonChanged(field, value) {
      this.form[field] =
        value.replace(/\s/g, '') !== '' ? JSON.parse(value) : {}
    },

    validate() {
      const metadataValid =
        !this.$refs?.metadataEditor ||
        this.$refs?.metadataEditor?.validateJson()
      const customPropertiesValid =
        !this.$refs?.customPropertiesEditor ||
        this.$refs?.customPropertiesEditor?.validateJson()
      return metadataValid && customPropertiesValid
    },

    updateEditors(value) {
      this.showEditors = !value
    }
  }
}
</script>
