<template>
  <el-form
    ref="form"
    :model="form"
    class="lg:-mb-4"
    :rules="!readonly ? rules : null"
    @submit.prevent="submitSigningConfiguration"
  >
    <h5 class="mt-0 text-th-color-header">
      {{ $t('pages.branches.edit.form.more.sections.signing.title') }}
    </h5>

    <el-row :gutter="20">
      <el-col :lg="8" :md="12">
        <!-- Signature Resource -->
        <el-form-item
          :label="
            $t('pages.branches.edit.form.more.sections.signing.resource.label')
          "
          prop="resource"
        >
          <el-input
            id="resource"
            v-model="form.resource"
            :readonly="readonly"
            :disabled="!isNewConfig"
          />
        </el-form-item>
      </el-col>

      <el-col :lg="8" :md="12">
        <!-- Signature Authentication -->
        <el-form-item
          :label="
            $t('pages.branches.edit.form.more.sections.signing.auth.label')
          "
          prop="auth"
        >
          <el-input
            id="auth"
            v-model="form.auth"
            :readonly="readonly"
            :type="isNewConfig ? undefined : 'password'"
            :disabled="!isNewConfig"
          />
        </el-form-item>
      </el-col>

      <el-col :lg="8">
        <!-- Submit -->
        <el-button
          :disabled="!isNewConfig || readonly"
          type="primary"
          native-type="submit"
          plain
          class="h-10 lg:mt-th-input-title xl:mt-th-input-title"
          v-text="
            $t(
              'pages.branches.edit.form.more.sections.signing.actions.add.label'
            )
          "
        />

        <!-- Display payload -->
        <payload-displayer
          v-if="receiptPayload"
          class="ml-4"
          :data="receiptPayload"
          :type="form.signingSystem"
        />
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import PayloadDisplayer from './fiscalisation/payload-displayer'

export default {
  components: {
    PayloadDisplayer
  },

  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },

    isNew: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isNewConfig: true,
      form: {
        resource: null,
        signingSystem: 'fiskaltrust',
        auth: null
      },
      rules: {
        resource: [
          {
            required: true,
            message: this.$t('pages.staff.edit.form.field_warnings.required'),
            trigger: 'blur'
          }
        ],
        auth: [
          {
            required: true,
            message: this.$t('pages.staff.edit.form.field_warnings.required'),
            trigger: 'blur'
          }
        ]
      }
    }
  },

  computed: {
    branchId() {
      return this.$route.params.id
    },

    receiptPayload() {
      return this.modelValue?.payload
    }
  },

  mounted() {
    this.isNewConfig = !this.modelValue?.resource
    this.form = { ...this.form, ...this.modelValue }
  },

  methods: {
    async submitSigningConfiguration() {
      if (!(await this.validate())) {
        return this.$message({
          type: 'warning',
          message: this.$t('common.forms.message.invalid_inputs')
        })
      }

      try {
        const { resource, auth, signingSystem } = this.form
        const payload = {
          cashbox: resource,
          cashbox_auth: auth
        }
        const { data } = await th
          .transactionsLegacy()
          .signing.initialise('branches', this.branchId, signingSystem, payload)

        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'transactions.signing',
          value: {
            type: 'fiskaltrust',
            resource_type: 'branches'
          }
        })

        await this.$store.dispatch('Config/saveClientAccountConfiguration')

        this.$emit('update:modelValue', data)

        this.$message({
          type: 'success',
          message: this.$t('common.success.action.update.single', {
            resource: this.$t('common.resource.branch.singular')
          })
        })
      } catch (err) {
        const errorMessage = this.$t(
          'pages.branches.edit.sections.fiscalisation.submit_error'
        )
        this.$logException(err, {
          message: errorMessage
        })
      }
    },

    // ----------------- Validate -----------------
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
